// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background-color: transparent;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.leaflet-container {
  outline: none;
}


.button {
  display: inline-block;
  padding: 8px 8px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: black;
  background-color: lightgrey;
  border: none;
  border-radius: 4px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: pre-line;
}

.button:hover {
  background-color: #1976d2;
}

.button:active {
  background-color: #1565c0;
}

.button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.3);
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,gBAAgB;EAChB,6BAA6B;AAC/B;;;AAGA;EACE;aACW;AACb;;AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;;AAGA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,YAAY;EACZ,2BAA2B;EAC3B,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,sCAAsC;EACtC,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,6CAA6C;AAC/C","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  overflow: hidden;\n  background-color: transparent;\n}\n\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n.leaflet-container {\n  outline: none;\n}\n\n\n.button {\n  display: inline-block;\n  padding: 8px 8px;\n  font-size: 12px;\n  font-weight: bold;\n  text-align: center;\n  text-decoration: none;\n  color: black;\n  background-color: lightgrey;\n  border: none;\n  border-radius: 4px;\n  margin: 5px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n  white-space: pre-line;\n}\n\n.button:hover {\n  background-color: #1976d2;\n}\n\n.button:active {\n  background-color: #1565c0;\n}\n\n.button:focus {\n  outline: none;\n  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
