export const buildingTypeColours = [
    { buildingType: 'EFH', colour: '#4f8c9d' },
    { buildingType: 'DHH', colour: '#47b2d0' },
    { buildingType: 'RH', colour: '#4751d0' },
    { buildingType: 'MFH', colour: '#8547D0' },
    { buildingType: 'GMFH', colour: '#e4335f' },
    { buildingType: 'HH', colour: '#e21d1d' },
    { buildingType: 'NWG', colour: '#fffe68' },
    { buildingType: 'Keine Angabe', colour: '#d3d3d3' }
];
export const sanierungsstandColours = [
    { buildingType: 'A+', colour:'#b0ff00' },
    { buildingType: 'A', colour: '#bed600' },
    { buildingType: 'B', colour: '#c1ae00' },
    { buildingType: 'C', colour: '#ba8800' },
    { buildingType: 'D', colour: '#ab6400' },
    { buildingType: 'E', colour: '#954200' },
    { buildingType: 'F', colour: '#7b2200' },
    { buildingType: 'G', colour: '#5c0000' },
    { buildingType: 'Keine Angabe', colour: '#d3d3d3' }
];


export const funcColours = [
    { funcType: 'Residential', colour: '#4f8c9d' },
    { funcType: 'Commerce & Industry', colour: '#b6e45c' },
    { funcType: 'Public Use & Services', colour: '#a4515b' },
    { funcType: 'Leasure & Religion', colour: '#e7986d' },
    { funcType: 'Mixed use', colour: '#1eefc9' },
    { funcType: 'Parking', colour: '#a2e1ca' },
    { funcType: 'Infrastructure', colour: '#334a56' },
    { funcType: 'Storage', colour: '#bd043a' },
    { funcType: 'Other', colour: '#d3d3d3' }
];

export const uniqueFunctions = [
    { fullText: 'Ferienhaus', funcType: 'Residential' },
    { fullText: 'Land- und forstwirtschaftliches Wohngebäude', funcType: 'Residential' },
    { fullText: 'Gartenhaus', funcType: 'Residential' },
    { fullText: 'Wohnhaus', funcType: 'Residential' },
    { fullText: 'Wochenendhaus', funcType: 'Residential' },
    { fullText: 'Wohngebäude', funcType: 'Residential' },
    { fullText: 'Wohnheim', funcType: 'Residential' },
    { fullText: 'Seniorenheim', funcType: 'Residential' },
    { fullText: 'Studenten-, Schülerwohnheim', funcType: 'Residential' },
    { fullText: 'Wohngebäude mit Gemeinbedarf', funcType: 'Residential' },
    { fullText: 'Schwesternwohnheim', funcType: 'Residential' },
    { fullText: 'Jugendherberge', funcType: 'Residential' },
    { fullText: 'Asylbewerberheim', funcType: 'Residential' },
    { fullText: 'Bootshaus', funcType: 'Residential' },
    { fullText: 'Hütte (mit Übernachtungsmöglichkeit)', funcType: 'Residential' },
    { fullText: 'Obdachlosenheim', funcType: 'Residential' },
    { fullText: 'Einzelhaus', funcType: 'Residential' },
    { fullText: 'Doppelhaus', funcType: 'Residential' },
    { fullText: 'Wohnblock in geschlossener Bauweise', funcType: 'Residential' },
    { fullText: 'Reihenhaus', funcType: 'Residential' },
    { fullText: 'Wohnhaus in Reihe', funcType: 'Residential' },
    { fullText: 'Freistehender Wohnblock', funcType: 'Residential' },
    { fullText: 'Wohnhaus (allgemein)', funcType: 'Residential' },
    { fullText: 'Beherbergung (allgemein)', funcType: 'Residential' },
    { fullText: 'Gruppenhaus', funcType: 'Residential' },
    { fullText: 'Hochhaus', funcType: 'Residential' },
    { fullText: 'Landwirtschaftliches Wohngebäude', funcType: 'Residential' },
    { fullText: 'Seniorenwohnhaus, Seniorenheim', funcType: 'Residential' },
    { fullText: 'Wohngebäude (soweit nicht OS 1311-1398)', funcType: 'Residential' },
    { fullText: 'Fremdenheim', funcType: 'Residential' },
    { fullText: 'Arbeitnehmerwohnheim, Schwesternwohnheim', funcType: 'Residential' },
    { fullText: 'Wohnheim (allgemein)', funcType: 'Residential' },
    { fullText: 'Studentenwohnheim, Schülerwohnheim', funcType: 'Residential' },
    { fullText: 'Behelfsmäßiges Wohngebäude', funcType: 'Residential' },
    { fullText: 'Hütte mit Übernachtungsmöglichkeit', funcType: 'Residential' },
    { fullText: 'Land- und forstwirtschaftliches Wohn- und Betriebsgebäude', funcType: 'Commerce & Industry' },
    { fullText: 'Stall', funcType: 'Commerce & Industry' },
    { fullText: 'Treibhaus', funcType: 'Commerce & Industry' },
    { fullText: 'Gebäude für Land- und Forstwirtschaft', funcType: 'Commerce & Industry' },
    { fullText: 'Gebäude für Gewerbe und Industrie', funcType: 'Commerce & Industry' },
    { fullText: 'Werkstatt', funcType: 'Commerce & Industry' },
    { fullText: 'Gebäude für Handel und Dienstleistungen', funcType: 'Commerce & Industry' },
    { fullText: 'Fabrik', funcType: 'Commerce & Industry' },
    { fullText: 'Land- und forstwirtschaftliches Betriebsgebäude', funcType: 'Commerce & Industry' },
    { fullText: 'Bürogebäude', funcType: 'Commerce & Industry' },
    { fullText: 'Gaststätte, Restaurant', funcType: 'Commerce & Industry' },
    { fullText: 'Produktionsgebäude', funcType: 'Commerce & Industry' },
    { fullText: 'Betriebsgebäude', funcType: 'Commerce & Industry' },
    { fullText: 'Forsthaus', funcType: 'Commerce & Industry' },
    { fullText: 'Jagdhaus, Jagdhütte', funcType: 'Commerce & Industry' },
    { fullText: 'Wirtschaftsgebäude', funcType: 'Commerce & Industry' },
    { fullText: 'Gebäude für Bewirtung', funcType: 'Commerce & Industry' },
    { fullText: 'Speditionsgebäude', funcType: 'Commerce & Industry' },
    { fullText: 'Treibhaus, Gewächshaus', funcType: 'Commerce & Industry' },
    { fullText: 'Hotel, Motel, Pension', funcType: 'Commerce & Industry' },
    { fullText: 'Kinderheim', funcType: 'Commerce & Industry' },
    { fullText: 'Sägewerk', funcType: 'Commerce & Industry' },
    { fullText: 'Sonstiges Gebäude für Gewerbe und Industrie', funcType: 'Commerce & Industry' },
    { fullText: 'Gebäude zur Entsorgung', funcType: 'Commerce & Industry' },
    { fullText: 'Straßenmeisterei', funcType: 'Commerce & Industry' },
    { fullText: 'Windmühle', funcType: 'Commerce & Industry' },
    { fullText: 'Gebäude für Fernmeldewesen', funcType: 'Commerce & Industry' },
    { fullText: 'Gebäude für Grundstoffgewinnung', funcType: 'Commerce & Industry' },
    { fullText: 'Gebäude für Wirtschaft oder Gewerbe', funcType: 'Commerce & Industry' },
    { fullText: 'Kiosk', funcType: 'Commerce & Industry' },
    { fullText: 'Einkaufszentrum', funcType: 'Commerce & Industry' },
    { fullText: 'Laden', funcType: 'Commerce & Industry' },
    { fullText: 'Geschäftsgebäude', funcType: 'Commerce & Industry' },
    { fullText: 'Kreditinstitut', funcType: 'Commerce & Industry' },
    { fullText: 'Tankstelle', funcType: 'Commerce & Industry' },
    { fullText: 'Gebäude für betriebliche Sozialeinrichtung', funcType: 'Commerce & Industry' },
    { fullText: 'Gebäude zur Müllverbrennung', funcType: 'Commerce & Industry' },
    { fullText: 'Finanzamt', funcType: 'Commerce & Industry' },
    { fullText: 'Kaufhaus', funcType: 'Commerce & Industry' },
    { fullText: 'Waschstraße, Waschanlage, Waschhalle', funcType: 'Commerce & Industry' },
    { fullText: 'Sanatorium', funcType: 'Commerce & Industry' },
    { fullText: 'Versicherung', funcType: 'Commerce & Industry' },
    { fullText: 'Betriebsgebäude zu Verkehrsanlagen (allgemein)', funcType: 'Commerce & Industry' },
    { fullText: 'Heizwerk', funcType: 'Commerce & Industry' },
    { fullText: 'Gebäude zur Versorgung', funcType: 'Commerce & Industry' },
    { fullText: 'Gebäude für Beherbergung', funcType: 'Commerce & Industry' },
    { fullText: 'Gewächshaus (Botanik)', funcType: 'Commerce & Industry' },
    { fullText: 'Betriebsgebäude für Schiffsverkehr', funcType: 'Commerce & Industry' },
    { fullText: 'Brauerei', funcType: 'Commerce & Industry' },
    { fullText: 'Apotheke', funcType: 'Commerce & Industry' },
    { fullText: 'Gewächshaus, verschiebbar', funcType: 'Commerce & Industry' },
    { fullText: 'Spielkasino', funcType: 'Commerce & Industry' },
    { fullText: 'Bergwerk', funcType: 'Commerce & Industry' },
    { fullText: 'Kesselhaus', funcType: 'Commerce & Industry' },
    { fullText: 'Förderturm', funcType: 'Commerce & Industry' },
    { fullText: 'Saline', funcType: 'Commerce & Industry' },
    { fullText: 'Sprungschanze (Anlauf)', funcType: 'Commerce & Industry' },
    { fullText: 'Gradierwerk', funcType: 'Commerce & Industry' },
    { fullText: 'Hochofen', funcType: 'Commerce & Industry' },
    { fullText: 'Mühle', funcType: 'Commerce & Industry' },
    { fullText: 'Schöpfwerk', funcType: 'Commerce & Industry' },
    { fullText: 'Werft (Halle)', funcType: 'Commerce & Industry' },
    { fullText: 'Gebäude für Gewerbe und Industrie (allgemein)', funcType: 'Commerce & Industry' },
    { fullText: 'Verwaltungsgebäude, Bürogebäude', funcType: 'Commerce & Industry' },
    { fullText: 'Hotel, Pension', funcType: 'Commerce & Industry' },
    { fullText: 'Landwirtschaftliches Betriebsgebäude (allgemein)', funcType: 'Commerce & Industry' },
    { fullText: 'Ladengebäude', funcType: 'Commerce & Industry' },
    { fullText: 'Handel (allgemein)', funcType: 'Commerce & Industry' },
    { fullText: 'Gebäude für Handel und Dienstleistungen (allgemein)', funcType: 'Commerce & Industry' },
    { fullText: 'Gewächshaus (allgemein)', funcType: 'Commerce & Industry' },
    { fullText: 'Gebäude für Land- und Forstwirtschaft (allgemein)', funcType: 'Commerce & Industry' },
    { fullText: 'Gaststätte', funcType: 'Commerce & Industry' },
    { fullText: 'Restauration (allgemein)', funcType: 'Commerce & Industry' },
    { fullText: 'Gewächshaus, Treibhaus', funcType: 'Commerce & Industry' },
    { fullText: 'Kreditinstitutsgebäude, Bankgebäude', funcType: 'Commerce & Industry' },
    { fullText: 'Wirtschafts- oder Industriegebäude (allgemein)', funcType: 'Commerce & Industry' },
    { fullText: 'Versicherungsgebäude', funcType: 'Commerce & Industry' },
    { fullText: 'Empfangsgebäude für Verkehrsteilnehmer (allgemein)', funcType: 'Commerce & Industry' },
    { fullText: 'Gewächshaus', funcType: 'Commerce & Industry' },
    { fullText: 'Auf Schienen verschiebbares Gewächshaus', funcType: 'Commerce & Industry' },
    { fullText: 'Gebäude für Botanik (allgemein)', funcType: 'Commerce & Industry' },
    { fullText: 'Gebäude f. Gewerbe u. Industrie (sow. nicht OS 1711-1798)', funcType: 'Commerce & Industry' },
    { fullText: 'Restauration (soweit nicht OS 1471-1477)', funcType: 'Commerce & Industry' },
    { fullText: 'Gebäude für Versorgung (soweit nicht OS 2511-2598)', funcType: 'Commerce & Industry' },
    { fullText: 'Werft', funcType: 'Commerce & Industry' },
    { fullText: 'Bergwerk, in Betrieb', funcType: 'Commerce & Industry' },
    { fullText: 'Bergwerk, außer Betrieb', funcType: 'Commerce & Industry' },
    { fullText: 'Gradierwerk, Saline (soweit nicht OS 3912)', funcType: 'Commerce & Industry' },
    { fullText: 'Gebäude f. Handel u. Dienst. (soweit nicht OS 1411-1498)', funcType: 'Commerce & Industry' },
    { fullText: 'Freizeit-, Vereinsheim, Dorfgemeinschafts-, Bürgerhaus', funcType: 'Public Use & Services' },
    { fullText: 'Allgemein bildende Schule', funcType: 'Public Use & Services' },
    { fullText: 'Gebäude zur Wasserversorgung', funcType: 'Public Use & Services' },
    { fullText: 'Gebäude für Forschungszwecke', funcType: 'Public Use & Services' },
    { fullText: 'Gebäude für Bildung und Forschung', funcType: 'Public Use & Services' },
    { fullText: 'Kinderkrippe, Kindergarten, Kindertagesstätte', funcType: 'Public Use & Services' },
    { fullText: 'Ärztehaus, Poliklinik', funcType: 'Public Use & Services' },
    { fullText: 'Feuerwehr', funcType: 'Public Use & Services' },
    { fullText: 'Gebäude zur Abwasserbeseitigung', funcType: 'Public Use & Services' },
    { fullText: 'Gebäude zur Versorgungsanlage', funcType: 'Public Use & Services' },
    { fullText: 'Berufsbildende Schule', funcType: 'Public Use & Services' },
    { fullText: 'Verwaltungsgebäude', funcType: 'Public Use & Services' },
    { fullText: 'Rathaus', funcType: 'Public Use & Services' },
    { fullText: 'Krankenhaus', funcType: 'Public Use & Services' },
    { fullText: 'Hochschulgebäude (Fachhochschule, Universität)', funcType: 'Public Use & Services' },
    { fullText: 'Polizei', funcType: 'Public Use & Services' },
    { fullText: 'Messehalle', funcType: 'Public Use & Services' },
    { fullText: 'Bibliothek, Bücherei', funcType: 'Public Use & Services' },
    { fullText: 'Gebäude für Gesundheitswesen', funcType: 'Public Use & Services' },
    { fullText: 'Gebäude für öffentliche Zwecke', funcType: 'Public Use & Services' },
    { fullText: 'Forschungsinstitut', funcType: 'Public Use & Services' },
    { fullText: 'Trauerhalle', funcType: 'Public Use & Services' },
    { fullText: 'Kaserne', funcType: 'Public Use & Services' },
    { fullText: 'Kreisverwaltung', funcType: 'Public Use & Services' },
    { fullText: 'Gericht', funcType: 'Public Use & Services' },
    { fullText: 'Justizvollzugsanstalt', funcType: 'Public Use & Services' },
    { fullText: 'Gebäude für Sicherheit und Ordnung', funcType: 'Public Use & Services' },
    { fullText: 'Gebäude der Kläranlage', funcType: 'Public Use & Services' },
    { fullText: 'Gebäude zur Abfallbehandlung', funcType: 'Public Use & Services' },
    { fullText: 'Wasserwerk', funcType: 'Public Use & Services' },
    { fullText: 'Zollamt', funcType: 'Public Use & Services' },
    { fullText: 'Post', funcType: 'Public Use & Services' },
    { fullText: 'Gebäude der Abfalldeponie', funcType: 'Public Use & Services' },
    { fullText: 'Krematorium', funcType: 'Public Use & Services' },
    { fullText: 'Botschaft, Konsulat', funcType: 'Public Use & Services' },
    { fullText: 'Wetterstation', funcType: 'Public Use & Services' },
    { fullText: 'Feuerwachturm', funcType: 'Public Use & Services' },
    { fullText: 'Touristisches Informationszentrum', funcType: 'Public Use & Services' },
    { fullText: 'Schullandheim', funcType: 'Public Use & Services' },
    { fullText: 'Badegebäude für medizinische Zwecke', funcType: 'Public Use & Services' },
    { fullText: 'Empfangsgebäude des botanischen Gartens', funcType: 'Public Use & Services' },
    { fullText: 'Almhütte', funcType: 'Public Use & Services' },
    { fullText: 'Kindergarten, Kindertagesstätte', funcType: 'Public Use & Services' },
    { fullText: 'Allgemeinbildende Schule', funcType: 'Public Use & Services' },
    { fullText: 'Postamt', funcType: 'Public Use & Services' },
    { fullText: 'Gesundheitseinrichtung (allgemein)', funcType: 'Public Use & Services' },
    { fullText: 'Heilanstalt, Pflegeanstalt', funcType: 'Public Use & Services' },
    { fullText: 'Berufsschule, Fachschule, Volkshochschule', funcType: 'Public Use & Services' },
    { fullText: 'Sicherheitseinrichtung (allgemein)', funcType: 'Public Use & Services' },
    { fullText: 'Öffentliche Verwaltung (allgemein)', funcType: 'Public Use & Services' },
    { fullText: 'Straßenverkehrsgebäude (allgemein)', funcType: 'Public Use & Services' },
    { fullText: 'Öffentliches Gebäude (allgemein)', funcType: 'Public Use & Services' },
    { fullText: 'Abfall (Lagergebäude)', funcType: 'Public Use & Services' },
    { fullText: 'Gebäude für Entsorgung (allgemein)', funcType: 'Public Use & Services' },
    { fullText: 'Gebäude für Abfallbeseitigung (allgemein)', funcType: 'Public Use & Services' },
    { fullText: 'Bedürfnisanstalt', funcType: 'Public Use & Services' },
    { fullText: 'Friedhofsgebäude (allgemein)', funcType: 'Public Use & Services' },
    { fullText: 'Bildungs-, Forschungseinrichtung (allgemein)', funcType: 'Public Use & Services' },
    { fullText: 'Abfallverbrennungsanlage', funcType: 'Public Use & Services' },
    { fullText: 'Forschungsgebäude', funcType: 'Public Use & Services' },
    { fullText: 'Betriebliche Sozialeinrichtung (Gebäude)', funcType: 'Public Use & Services' },
    { fullText: 'Fachhochschule, Universität', funcType: 'Public Use & Services' },
    { fullText: 'Bildungs-, Forschungseinr. (soweit nicht OS 1121-1127)', funcType: 'Public Use & Services' },
    { fullText: 'Öffentliche Verwaltung (soweit nicht OS 1111-1117)', funcType: 'Public Use & Services' },
    { fullText: 'Gesundheitseinrichtung (soweit nicht OS 1151-1157)', funcType: 'Public Use & Services' },
    { fullText: 'Parlament', funcType: 'Public Use & Services' },
    { fullText: 'Abfallbeseitigung (soweit nicht OS 2621-2627)', funcType: 'Public Use & Services' },
    { fullText: 'Friedhofsgebäude (soweit nicht OS 1181-1187)', funcType: 'Public Use & Services' },
    { fullText: 'Gebäude für Sportzwecke', funcType: 'Leasure & Religion' },
    { fullText: 'Kapelle', funcType: 'Leasure & Religion' },
    { fullText: 'Gebäude zur Freizeitgestaltung', funcType: 'Leasure & Religion' },
    { fullText: 'Gebäude für Erholungszwecke', funcType: 'Leasure & Religion' },
    { fullText: 'Kloster', funcType: 'Leasure & Religion' },
    { fullText: 'Reithalle', funcType: 'Leasure & Religion' },
    { fullText: 'Gebäude zum Sportplatz', funcType: 'Leasure & Religion' },
    { fullText: 'Zuschauertribüne, überdacht', funcType: 'Leasure & Religion' },
    { fullText: 'Heilanstalt, Pflegeanstalt, Pflegestation', funcType: 'Leasure & Religion' },
    { fullText: 'Gemeindehaus', funcType: 'Leasure & Religion' },
    { fullText: 'Seniorenfreizeitstätte', funcType: 'Leasure & Religion' },
    { fullText: 'Aquarium, Terrarium, Voliere', funcType: 'Leasure & Religion' },
    { fullText: 'Badegebäude', funcType: 'Leasure & Religion' },
    { fullText: 'Gebäude für soziale Zwecke', funcType: 'Leasure & Religion' },
    { fullText: 'Jugendfreizeitheim', funcType: 'Leasure & Religion' },
    { fullText: 'Campingplatzgebäude', funcType: 'Leasure & Religion' },
    { fullText: 'Kirche', funcType: 'Leasure & Religion' },
    { fullText: 'Kantine', funcType: 'Leasure & Religion' },
    { fullText: 'Kirchturm', funcType: 'Leasure & Religion' },
    { fullText: 'Moschee', funcType: 'Leasure & Religion' },
    { fullText: 'Zuschauertribüne, nicht überdacht', funcType: 'Leasure & Religion' },
    { fullText: 'Gebäude für religiöse Zwecke', funcType: 'Leasure & Religion' },
    { fullText: 'Sport-, Turnhalle', funcType: 'Leasure & Religion' },
    { fullText: 'Festsaal', funcType: 'Leasure & Religion' },
    { fullText: 'Gebäude für Kurbetrieb', funcType: 'Leasure & Religion' },
    { fullText: 'Kegel-, Bowlinghalle', funcType: 'Leasure & Religion' },
    { fullText: 'Freizeit- und Vergnügungsstätte', funcType: 'Leasure & Religion' },
    { fullText: 'Gebäude im Zoo', funcType: 'Leasure & Religion' },
    { fullText: 'Tierschauhaus', funcType: 'Leasure & Religion' },
    { fullText: 'Hallenbad', funcType: 'Leasure & Religion' },
    { fullText: 'Denkmal', funcType: 'Leasure & Religion' },
    { fullText: 'Schloss', funcType: 'Leasure & Religion' },
    { fullText: 'Gebäude für kulturelle Zwecke', funcType: 'Leasure & Religion' },
    { fullText: 'Museum', funcType: 'Leasure & Religion' },
    { fullText: 'Gebäude im Freibad', funcType: 'Leasure & Religion' },
    { fullText: 'Veranstaltungsgebäude', funcType: 'Leasure & Religion' },
    { fullText: 'Stadion', funcType: 'Leasure & Religion' },
    { fullText: 'Gebäude für andere Erholungseinrichtung', funcType: 'Leasure & Religion' },
    { fullText: 'Zuschauertribüne', funcType: 'Leasure & Religion' },
    { fullText: 'Gebäude im Stadion', funcType: 'Leasure & Religion' },
    { fullText: 'Kino', funcType: 'Leasure & Religion' },
    { fullText: 'Markthalle', funcType: 'Leasure & Religion' },
    { fullText: 'Gotteshaus', funcType: 'Leasure & Religion' },
    { fullText: 'Theater, Oper', funcType: 'Leasure & Religion' },
    { fullText: 'Konzertgebäude', funcType: 'Leasure & Religion' },
    { fullText: 'Empfangsgebäude des Zoos', funcType: 'Leasure & Religion' },
    { fullText: 'Tempel', funcType: 'Leasure & Religion' },
    { fullText: 'Synagoge', funcType: 'Leasure & Religion' },
    { fullText: 'Christliche Kirche', funcType: 'Leasure & Religion' },
    { fullText: 'Gemeindehaus, Küsterei', funcType: 'Leasure & Religion' },
    { fullText: 'Sporthalle', funcType: 'Leasure & Religion' },
    { fullText: 'Kirchliche Einrichtung (allgemein)', funcType: 'Leasure & Religion' },
    { fullText: 'Gebäude für Erholung (allgemein)', funcType: 'Leasure & Religion' },
    { fullText: 'Gebäude für Sport (allgemein)', funcType: 'Leasure & Religion' },
    { fullText: 'Gebäude für Versorgung (allgemein)', funcType: 'Leasure & Religion' },
    { fullText: 'Kulturelle Einrichtung (allgemein)', funcType: 'Leasure & Religion' },
    { fullText: 'Freizeitheim, Dorfgemeinschaftshaus, Bürgerhaus', funcType: 'Leasure & Religion' },
    { fullText: 'Sozialeinrichtung (allgemein)', funcType: 'Leasure & Religion' },
    { fullText: 'Gotteshaus einer anderen Religionsgemeinschaft', funcType: 'Leasure & Religion' },
    { fullText: 'Gebäude für Messe, Ausstellung', funcType: 'Leasure & Religion' },
    { fullText: 'Sportplatzgebäude', funcType: 'Leasure & Religion' },
    { fullText: 'Vergnügungsstätte (allgemein)', funcType: 'Leasure & Religion' },
    { fullText: 'Kegelhalle, Bowlinghalle', funcType: 'Leasure & Religion' },
    { fullText: 'Freizeithaus (allgemein)', funcType: 'Leasure & Religion' },
    { fullText: 'Tribüne', funcType: 'Leasure & Religion' },
    { fullText: 'Kurgebäude (allgemein)', funcType: 'Leasure & Religion' },
    { fullText: 'Badegebäude (allgemein)', funcType: 'Leasure & Religion' },
    { fullText: 'Gebäude der Freibadanlage', funcType: 'Leasure & Religion' },
    { fullText: 'Aquarium, Terrarium', funcType: 'Leasure & Religion' },
    { fullText: 'Badegebäude (soweit nicht OS 2821-2827)', funcType: 'Leasure & Religion' },
    { fullText: 'Kirchliche Einrichtung (soweit nicht OS 1141-1147)', funcType: 'Leasure & Religion' },
    { fullText: 'Öffentliches Gebäude (soweit nicht OS 1191-1197)', funcType: 'Leasure & Religion' },
    { fullText: 'Sozialeinrichtung (soweit nicht OS 1161-1167)', funcType: 'Leasure & Religion' },
    { fullText: 'Pflanzenschauhaus', funcType: 'Leasure & Religion' },
    { fullText: 'Gebäude für Erholung (soweit nicht OS 2811-2898)', funcType: 'Leasure & Religion' },
    { fullText: 'Wohngebäude mit Handel und Dienstleistungen', funcType: 'Mixed use' },
    { fullText: 'Gebäude für Gewerbe und Industrie mit Wohnen', funcType: 'Mixed use' },
    { fullText: 'Gemischt genutztes Gebäude mit Wohnen', funcType: 'Mixed use' },
    { fullText: 'Wohngebäude mit Gewerbe und Industrie', funcType: 'Mixed use' },
    { fullText: 'Bauernhaus', funcType: 'Mixed use' },
    { fullText: 'Wohn- und Bürogebäude', funcType: 'Mixed use' },
    { fullText: 'Gebäude für Handel und Dienstleistung mit Wohnen', funcType: 'Mixed use' },
    { fullText: 'Wohn- und Geschäftsgebäude', funcType: 'Mixed use' },
    { fullText: 'Wohn- und Betriebsgebäude', funcType: 'Mixed use' },
    { fullText: 'Gebäude für öffentliche Zwecke mit Wohnen', funcType: 'Mixed use' },
    { fullText: 'Wohn- und Wirtschaftsgebäude', funcType: 'Mixed use' },
    { fullText: 'Wohn- und Verwaltungsgebäude', funcType: 'Mixed use' },
    { fullText: 'Landwirtschaftliches Wohn- und Betriebsgebäude', funcType: 'Mixed use' },
    { fullText: 'Öffentliches Gebäude mit Wohnungen', funcType: 'Mixed use' },
    { fullText: 'Gemischt genutztes Gebäude mit Wohnungen', funcType: 'Mixed use' },
    { fullText: 'Landwirtschaftliches Wohn-und Betriebsgebäude (allgem.)', funcType: 'Mixed use' },
    { fullText: 'Wohn- oder öffentliches Gebäude (allgemein)', funcType: 'Mixed use' },
    { fullText: 'Gemischt gen. Gebäude m. Wohnungen (sow. nicht OS 2111-2198)', funcType: 'Mixed use' },
    { fullText: 'Garage', funcType: 'Parking' },
    { fullText: 'Parkhaus', funcType: 'Parking' },
    { fullText: 'Gebäude zum Parken', funcType: 'Parking' },
    { fullText: 'Parkdeck', funcType: 'Parking' },
    { fullText: 'Einzelgarage', funcType: 'Parking' },
    { fullText: 'Sammelgarage', funcType: 'Parking' },
    { fullText: 'Tiefgarage', funcType: 'Parking' },
    { fullText: 'Doppelgarage', funcType: 'Parking' },
    { fullText: 'Überdachter Stellplatz (soweit nicht OS 5315)', funcType: 'Parking' },
    { fullText: 'Gebäude für Parken (allgemein)', funcType: 'Parking' },
    { fullText: 'Solarzellen', funcType: 'Infrastructure' },
    { fullText: 'Betriebsgebäude für Schienenverkehr', funcType: 'Infrastructure' },
    { fullText: 'Carport', funcType: 'Infrastructure' },
    { fullText: 'Gebäude zur Elektrizitätsversorgung', funcType: 'Infrastructure' },
    { fullText: 'Elektrizitätswerk', funcType: 'Infrastructure' },
    { fullText: 'Funkmast', funcType: 'Infrastructure' },
    { fullText: 'Umformer', funcType: 'Infrastructure' },
    { fullText: 'Toilette', funcType: 'Infrastructure' },
    { fullText: 'Bahnhofsgebäude', funcType: 'Infrastructure' },
    { fullText: 'Portalkran', funcType: 'Infrastructure' },
    { fullText: 'Laufkran', funcType: 'Infrastructure' },
    { fullText: 'Stellwerk, Blockstelle', funcType: 'Infrastructure' },
    { fullText: 'Gebäude zur Energieversorgung', funcType: 'Infrastructure' },
    { fullText: 'Umspannwerk', funcType: 'Infrastructure' },
    { fullText: 'Betriebsgebäude für Straßenverkehr', funcType: 'Infrastructure' },
    { fullText: 'Pumpwerk (nicht für Wasserversorgung)', funcType: 'Infrastructure' },
    { fullText: 'Pumpstation', funcType: 'Infrastructure' },
    { fullText: 'Gebäude zur Gasversorgung', funcType: 'Infrastructure' },
    { fullText: 'Windrad', funcType: 'Infrastructure' },
    { fullText: 'Wasserbehälter', funcType: 'Infrastructure' },
    { fullText: 'Sende-,Funkturm', funcType: 'Infrastructure' },
    { fullText: 'Biogasanlage', funcType: 'Infrastructure' },
    { fullText: 'Betriebsgebäude für Flugverkehr', funcType: 'Infrastructure' },
    { fullText: 'Betriebsgebäude zur Schleuse', funcType: 'Infrastructure' },
    { fullText: 'Bahnwärterhaus', funcType: 'Infrastructure' },
    { fullText: 'Gebäude zum Busbahnhof', funcType: 'Infrastructure' },
    { fullText: 'Gasometer', funcType: 'Infrastructure' },
    { fullText: 'Betriebsgebäude zur Seilbahn', funcType: 'Infrastructure' },
    { fullText: 'Betriebsgebäude des Güterbahnhofs', funcType: 'Infrastructure' },
    { fullText: 'Turbinenhaus', funcType: 'Infrastructure' },
    { fullText: 'Wassermühle', funcType: 'Infrastructure' },
    { fullText: 'Drehkran', funcType: 'Infrastructure' },
    { fullText: 'Gebäude an unterirdischen Leitungen', funcType: 'Infrastructure' },
    { fullText: 'Wachturm', funcType: 'Infrastructure' },
    { fullText: 'Kontrollturm', funcType: 'Infrastructure' },
    { fullText: 'Wasserturm', funcType: 'Infrastructure' },
    { fullText: 'Rundfunk, Fernsehen', funcType: 'Infrastructure' },
    { fullText: 'Gebäude zum U-Bahnhof', funcType: 'Infrastructure' },
    { fullText: 'Gaswerk', funcType: 'Infrastructure' },
    { fullText: 'Gebäude zum S-Bahnhof', funcType: 'Infrastructure' },
    { fullText: 'Flughafengebäude', funcType: 'Infrastructure' },
    { fullText: 'Umformer (soweit nicht OS 3447)', funcType: 'Infrastructure' },
    { fullText: 'Abwasserbeseitigung (soweit nicht OS 2611-2617)', funcType: 'Infrastructure' },
    { fullText: 'Oberirdisches Gebäude an unterirdischen Leitungen', funcType: 'Infrastructure' },
    { fullText: 'Gebäude für Wasserversorgung (allgemein)', funcType: 'Infrastructure' },
    { fullText: 'Pumpwerk', funcType: 'Infrastructure' },
    { fullText: 'Grundstoff (allgemein)', funcType: 'Infrastructure' },
    { fullText: 'Gebäude für Funk- und Fernmeldewesen (allgemein)', funcType: 'Infrastructure' },
    { fullText: 'Gebäude für Elektrizitätsversorgung (allgemein)', funcType: 'Infrastructure' },
    { fullText: 'Kläranlage', funcType: 'Infrastructure' },
    { fullText: 'Sendeturm, Fernmeldeturm', funcType: 'Infrastructure' },
    { fullText: 'Rundfunkanstalt, Fernsehanstalt', funcType: 'Infrastructure' },
    { fullText: 'Gebäude für Verkehr (allgemein)', funcType: 'Infrastructure' },
    { fullText: 'Betriebsgebäude einer Abfalldeponie', funcType: 'Infrastructure' },
    { fullText: 'Schienenverkehrsgebäude (allgemein)', funcType: 'Infrastructure' },
    { fullText: 'Gebäude für Luftfahrt (allgemein)', funcType: 'Infrastructure' },
    { fullText: 'Gebäude für Seilbahn (allgemein)', funcType: 'Infrastructure' },
    { fullText: 'Wassermühle, Schöpfwerk', funcType: 'Infrastructure' },
    { fullText: 'S-Bahnhof', funcType: 'Infrastructure' },
    { fullText: 'Omnibusbahnhof', funcType: 'Infrastructure' },
    { fullText: 'Güterbahnhofsgebäude', funcType: 'Infrastructure' },
    { fullText: 'Elektrizitätsversorgung (soweit nicht OS 2521-2527)', funcType: 'Infrastructure' },
    { fullText: 'Schienenverkehr (soweit nicht OS 1190 oder 2321-2327)', funcType: 'Infrastructure' },
    { fullText: 'Pumpstelle', funcType: 'Infrastructure' },
    { fullText: 'U-Bahnhof', funcType: 'Infrastructure' },
    { fullText: 'Schifffahrtsgebäude (allgemein)', funcType: 'Infrastructure' },
    { fullText: 'Schleuse', funcType: 'Infrastructure' },
    { fullText: 'Spannwerk (Gebäude)', funcType: 'Infrastructure' },
    { fullText: 'Schuppen', funcType: 'Storage' },
    { fullText: 'Scheune', funcType: 'Storage' },
    { fullText: 'Lagerhalle, Lagerschuppen, Lagerhaus', funcType: 'Storage' },
    { fullText: 'Scheune und Stall', funcType: 'Storage' },
    { fullText: 'Stall für Tiergroßhaltung', funcType: 'Storage' },
    { fullText: 'Flugzeughalle', funcType: 'Storage' },
    { fullText: 'Silo', funcType: 'Storage' },
    { fullText: 'Gebäude für Vorratshaltung', funcType: 'Storage' },
    { fullText: 'Fahrzeughalle', funcType: 'Storage' },
    { fullText: 'Tank', funcType: 'Storage' },
    { fullText: 'Kühlhaus', funcType: 'Storage' },
    { fullText: 'Lokschuppen, Wagenhalle', funcType: 'Storage' },
    { fullText: 'Speichergebäude', funcType: 'Storage' },
    { fullText: 'Müllbunker', funcType: 'Storage' },
    { fullText: 'Stall im Zoo', funcType: 'Storage' },
    { fullText: 'Lagerhalle', funcType: 'Storage' },
    { fullText: 'Lagerung (allgemein)', funcType: 'Storage' },
    { fullText: 'Speicher (soweit nicht OS 2725, 3931)', funcType: 'Storage' },
    { fullText: 'Futtersilo (soweit nicht OS 1742, 3931)', funcType: 'Storage' },
    { fullText: 'Gebäude für Tiergroßhaltung', funcType: 'Storage' },
    { fullText: 'Stellwerk, Blockstellengebäude', funcType: 'Storage' },
    { fullText: 'Öltank', funcType: 'Storage' },
    { fullText: 'Lagerschuppen', funcType: 'Storage' },
    { fullText: 'Lagerung (soweit nicht OS 1741-1747)', funcType: 'Storage' },
    { fullText: 'Überdachung', funcType: 'Other' },
    { fullText: 'Friedhofsgebäude', funcType: 'Other' },
    { fullText: 'Wartehalle', funcType: 'Other' },
    { fullText: 'Schutzhütte', funcType: 'Other' },
    { fullText: 'Hütte (ohne Übernachtungsmöglichkeit)', funcType: 'Other' },
    { fullText: 'Sonstiges', funcType: 'Other' },
    { fullText: 'Schutzbunker', funcType: 'Other' },
    { fullText: 'Stadtmauer', funcType: 'Other' },
    { fullText: 'Nach Quellenlage nicht zu spezifizieren', funcType: 'Other' },
    { fullText: 'Schornstein', funcType: 'Other' },
    { fullText: 'Kühlturm', funcType: 'Other' },
    { fullText: 'Befestigung (Burgruine)', funcType: 'Other' },
    { fullText: 'Mast', funcType: 'Other' },
    { fullText: 'Aussichtsturm', funcType: 'Other' },
    { fullText: 'Empfangsgebäude', funcType: 'Other' },
    { fullText: 'Stadt-,Torturm', funcType: 'Other' },
    { fullText: 'Historische Mauer', funcType: 'Other' },
    { fullText: 'Burg, Festung', funcType: 'Other' },
    { fullText: 'Schloss-, Burgturm', funcType: 'Other' },
    { fullText: 'Sonstige historische Mauer', funcType: 'Other' },
    { fullText: 'Transportgebäude', funcType: 'Other' },
    { fullText: 'Kran', funcType: 'Other' },
    { fullText: 'Kleines, untergeordnetes Gebäude', funcType: 'Other' },
    { fullText: 'Ungenutztes Gebäude', funcType: 'Other' },
    { fullText: 'Schloss, Burg', funcType: 'Other' },
    { fullText: 'Unterirdisches Gebäude (soweit nicht OS 2363)', funcType: 'Other' },
    { fullText: 'Gebäude (allgemein)', funcType: 'Other' },
    { fullText: 'Hütte ohne Übernachtungsmöglichkeit', funcType: 'Other' },
    { fullText: 'Treppe, Freitreppe', funcType: 'Other' },
    { fullText: 'Wartestand, überdacht; offene Halle (sow. nicht OS 5402)', funcType: 'Other' },
    { fullText: 'Raststätte an Autobahn oder Fernstraße', funcType: 'Other' },
]