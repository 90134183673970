import React from 'react';

function AggregateBuildingInfo({ aggregateData, downloadJSON, selectedGeoJSONData }) {
    // Helper function to parse building types into 2D table format
    const parseBuildingTypes = () => {
        const buildingCategories = {};

        Object.entries(aggregateData.buildingTypes).forEach(([key, value]) => {
            const [type, year] = key.split('_');
            if (!buildingCategories[type]) {
                buildingCategories[type] = {};
            }
            buildingCategories[type][year] = value;
        });

        return buildingCategories;
    };

    const buildingTypes2D = parseBuildingTypes();

    const energyClassMapping = {
        1: 'A+',
        2: 'A',
        3: 'B',
        4: 'C',
        5: 'D',
        6: 'E',
        7: 'F',
        8: 'G',
        9: 'H',
    };
    const energyClasses = Object.fromEntries(Object.entries(aggregateData.energyClasses).map(([key, value]) => [energyClassMapping[key] || 'Unknown', value]));
    return (
        <div style={{ padding: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2>Selection</h2>
                {aggregateData.objectCount !== 0 && (
                    <button className='button' onClick={() => downloadJSON(selectedGeoJSONData, 'aggregate_data.json')}>
                        Download
                    </button>
                )}
            </div>
            {aggregateData.objectCount !== 0 ? (
                <>
                    <table>
                        <tbody>
                            <tr><td style={{ width: '120px' }}><b>Object count:</b></td><td style={{ width: '120px', textAlign: 'right' }}>{aggregateData.objectCount}</td></tr>
                            <tr><td style={{ width: '120px' }}><b>Heat area (m²):</b></td><td style={{ width: '120px', textAlign: 'right' }}>{aggregateData.totalArea.toFixed(2)}</td></tr>
                            <tr><td style={{ width: '120px' }}><b>Heat demand (kWh/a):</b></td><td style={{ width: '120px', textAlign: 'right' }}>{aggregateData.totalHeatDemand.toFixed(2)}</td></tr>

                            <tr style={{ padding: '10px' }}>
                                <td colSpan="2" style={{ textAlign: 'center' }}><b>Building type counts</b></td>
                            </tr>

                            <tr>
                                <td><b>Type</b></td>
                                {Object.keys(buildingTypes2D['EFH']).map(year => (
                                    <td key={year} style={{ width: '100px', textAlign: 'center' }}><b>{year}</b></td>
                                ))}
                            </tr>

                            {Object.keys(buildingTypes2D).map(type => (
                                <tr key={type}>
                                    <td><b>{type}</b></td>
                                    {Object.keys(buildingTypes2D['EFH']).map(year => (
                                        <td key={year} style={{ textAlign: 'right' }}>
                                            {buildingTypes2D[type][year] || '-'}
                                        </td>
                                    ))}
                                </tr>
                            ))}

                            <tr style={{ padding: '10px' }}>
                                <td colSpan="2" style={{ textAlign: 'center' }}><b>Energy Class counts </b></td>
                            </tr>

                            {Object.entries(energyClasses).map(([type, count]) => (
                                <tr key={type}>
                                    <td><b>{ type ? type + ":" : "Keine Angabe:"}</b></td>
                                    <td>{count}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : <p>No selection</p>}
        </div>
    );
}

export default AggregateBuildingInfo;
